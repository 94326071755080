a {
  padding: 0 2vw 0 0;
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.nav-bar {
  margin: 2vh 2vw 2vh 40vw;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
}

.main-text {
  padding: 15vh 10vw 0;
  min-height: 65vh;
}

.project {
  padding-bottom: 10vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.project-content {
  padding-bottom: 5vh;
}

.project-img {
  width: 80vw;
  height: auto;
  margin: 0 auto;
}

.footer {
  margin: 0 50vw 2vh 10vw;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
}

.link {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: .3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
}

@media only screen and (min-width: 800px) {
  .project-img {
      width: 25vw;
      height: auto;
  }

  .project-content {
    width: 45vw;
  }
}